.header {
  position: fixed;
  width: 100%;
  z-index: 1000;
  background: #fff;
  box-shadow: 0 10px 100px rgba(0, 0, 0, 0.1);

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 5rem;
    @include respond(tab-port) {
      padding: 0 2rem;
    }
  }

  &__logo-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #333;
    transition: color .3s;

    &:hover {
      color: $themeClrPrimary;
    }
  }
  &__logo-img-cont {
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 1.5rem;
    background: #fff;
    perspective: 800px;
    display: inline-block;

    @include respond(tab-port) {
      width: 4.5rem;
      height: 4.5rem;
      margin-right: 1.2rem;
    }
  }

  &__logo-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    display: block;
    border-radius: 50%;
  }

  .avatar {
    width: 100%;
    height: 100%;
    position: absolute;
    transform-style: preserve-3d;
    animation: flipping 8s 7s infinite;
  }

  .avatar .face {
    width: 100%;
    height: 100%;
    position: absolute;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
  }

  .avatar .back {
    transform: rotateY( 180deg );
    box-sizing: border-box;
  }

  &__logo-title {
    font-size: 1.8rem;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1px;
  }
  &__links {
    display: flex;
    @include respond(phone) {
      display: none;
    }
  }
  &__link {
    padding: 2.2rem 2rem;
    display: inline-block;
    font-size: 1.6rem;
    color: #333;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: bold;
    transition: color .3s;

    &:hover {
      color: $themeClrPrimary;
    }
    @include respond(tab-port) {
      padding: 3rem 1.8rem;
      font-size: 1.5rem;
    }
  }

  &__main-ham-menu-cont {
    display: none;
    width: 3rem;

    padding: 2.2rem 0;

    @include respond(phone) {
      display: block;
    }
  }
  &__main-ham-menu {
    width: 100%;
  }
  &__main-ham-menu-close {
    width: 100%;
  }

  &__sm-menu {
    background: #fff;
    position: absolute;
    width: 100%;
    top: 100%;
    visibility: hidden;
    opacity: 0;
    transition: all .3s;
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);

    &--active {
      visibility: hidden;
      opacity: 0;

      @include respond(phone) {
        visibility: visible;
        opacity: 1;
      }
    }
  }
  &__sm-menu-link {
    a {
      display: block;
      text-decoration: none;
      padding: 2.5rem 3rem;
      font-size: 1.6rem;
      color: #333;
      text-align: right;
      border-bottom: 1px solid #eee;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 2px;
      transition: color .3s;

      &:hover {
        color: $themeClrPrimary;
      }
    }
    &:first-child {
      a {
        border-top: 1px solid #eee;
      }
    }
  }
  &__sm-menu-link:last-child {
    border-bottom: 0;
  }
}

@keyframes flipping {
  0% {
    transform: rotateY( 0 );
  }
  25% {
    transform: rotateY( 180deg );
  }
  50% {
    transform: rotateY( 180deg );
  }
  75% {
    transform: rotateY( 0 );
  }
}
