.mouse {
  width: 25px;
  height: 40px;
  border: 2px solid #eee;
  border-radius: 60px;
  position: relative;
  overflow: hidden;
  &::before {
    content: '';
    width: 5px;
    height: 5px;
    position: absolute;
    top: 7px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #eee;
    border-radius: 50%;
    opacity: 1;
    animation: wheel 1.3s infinite;
  }
}

@keyframes wheel {
  to {
    opacity: 0;
    top: 27px;
  }
}

@-webkit-keyframes wheel {
  to {
    opacity: 0;
    top: 27px;
  }
}
