@import 'abstracts/variables';
@import 'abstracts/mixins';

@import 'base/base';

@import 'abstracts/utilities';

@import 'pages/home';

@import 'components/header';
@import 'components/footer';
/*@import 'components/skills';*/
@import 'components/mouse-scroll';
