.home-screen {
  color: #fff;
  background: linear-gradient(
      to right,
      rgba($themeClrPrimary, 0.8),
      rgba($themeClrPrimary, 0.8)
    ),
    url(../images/common/common-bg.svg);
  background-position: center;

  height: 100vh;
  min-height: 80rem;
  max-height: 120rem;
  position: relative;

  @include respond(phone) {
    height: unset;
    min-height: unset;
  }

  &__mouse-scroll-cont {
    position: absolute;
    bottom: 3%;
    left: 50%;
    transform: translateX(-50%);

    @include respond(phone) {
      display: none;
    }
  }

  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 90rem;
    width: 92%;

    @include respond(phone) {
      padding: 19rem 0 13rem 0;
      margin: auto;
      position: static;
      transform: translate(0, 0);
    }
  }

  &__info {
    margin: 3rem auto 0 auto;
    max-width: 80rem;
  }
  &__cta {
    margin-top: 5rem;
    text-align: center;
  }
}

.about {
  background: rgb(250, 250, 250);

  &__main {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    grid-gap: 5rem;
    margin-bottom: 3rem;

    @include respond(tab-port) {
      grid-template-columns: 1fr;
      grid-gap: 2rem;
      margin-bottom: 8rem;
    }

    @include respond(tab-port) {
      text-align: left;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &-img-cont {
      overflow: hidden;
      padding: 2rem 0;
    }

    &-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      display: block;
      border-radius: 50%;
    }
  }

  &__sub {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    color: #676767;
    font-size: 2.3rem;
    font-weight: normal;
    line-height: 1.6;

    @include respond(phone) {
      font-size: 1.8rem;
    }
  }

  &-details {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 6rem 10rem;
    @include respond(tab-port) {
      grid-template-columns: 1fr;
      grid-gap: 4rem 8rem;
    }
  }

  &-title {
    font-weight: bold;
    font-size: 2.8rem;
    margin-bottom: 3rem;
    @include respond(phone) {
      font-size: 2.4rem;
    }
  }

  &-subtitle {
    font-weight: bold;
    font-size: 2.3rem;
    margin-top: 2rem;
    margin-bottom: 1.4rem;
    @include respond(phone) {
      font-size: 1.9rem;
    }
  }

  &-topics-ul {
    li {
      margin-left: 2.2rem;
      list-style: outside;
      font-size: 1.8rem;
      color: #676767;
      line-height: 1.7;
    }
  }
}

.projects {
  &__row {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    grid-gap: 5rem;
    margin-bottom: 11rem;

    @include respond(tab-port) {
      grid-template-columns: 1fr;
      grid-gap: 2rem;
      margin-bottom: 8rem;
    }

    @include respond(tab-port) {
      text-align: left;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &-img-cont {
      overflow: hidden;
      padding: 2rem 0;
    }
    &-img {
      padding: 1rem 2rem;
      width: 100%;
      display: block;
      object-fit: cover;
    }

    &-content {
      padding: 2rem 0;
      display: flex;
      //justify-content: center;
      flex-direction: column;
      align-items: flex-start;

      @include respond(tab-port) {
        align-items: baseline;
      }

      &-title {
        font-weight: bold;
        font-size: 2.8rem;
        margin-bottom: 2rem;
        @include respond(phone) {
          font-size: 2.4rem;
        }
      }

      &-desc {
        font-size: 1.8rem;
        color: #676767;
        max-width: 60rem;
        line-height: 1.7;
        margin-bottom: 1rem;
        @include respond(phone) {
          font-size: 1.7rem;
        }
      }

      ul {
        margin-bottom: 1rem;

        li {
          margin-left: 2.2rem;
          list-style: outside;
          font-size: 1.8rem;
          color: #676767;
          line-height: 1.7;
        }
      }
    }
  }
}

.contact {
  background: linear-gradient(
      to right,
      rgba($themeClrPrimary, 0.8),
      rgba($themeClrPrimary, 0.8)
    ),
    url(../images/common/common-bg.svg);
  background-size: cover;
  background-position: center;

  &__form-container {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background: rgba(#fff, 2);
    padding: 4rem;
    margin-top: 6rem;
    max-width: 80rem;
    text-align: right;
    width: 95%;
    border-radius: $commonBorderRadius;

    margin: 5rem auto 0 auto;
    @include respond(phone) {
      padding: 3rem;
    }
  }

  &__form-field {
    margin-bottom: 4rem;
    @include respond(phone) {
      margin-bottom: 3rem;
    }
  }

  &__form-label, &__form-message {
    color: #666;
    font-size: 1.4rem;
    letter-spacing: 1px;
    font-weight: bold;
    margin-bottom: 1rem;
    display: block;
    text-align: left;

    &-success {
      color: $themeClrPrimary;
    }

    &-error {
      color: $themeClrError;
    }
  }

  &__form-input {
    color: #333;
    padding: 2rem;
    width: 100%;
    border: 1px solid rgb(235, 235, 235);
    font-size: 1.6rem;
    letter-spacing: 0px;
    background: rgba(rgb(240, 240, 240), 1);
    border-radius: $commonBorderRadius;
    font-weight: bold;
    /* Code for Modern Browsers */
    &::placeholder {
      color: #999;
      font-weight: bold;
      font-size: 1.6rem;
    }

    /* Code for WebKit, Blink, Edge */
    &::-webkit-input-placeholder {
      color: #999;
      font-weight: bold;
      font-size: 1.6rem;
    }

    /* Code for Internet Explorer 10-11 */
    &:-ms-input-placeholder {
      color: #999;
      font-weight: bold;
      font-size: 1.6rem;
    }

    /* Code for Microsoft Edge */
    &::-ms-input-placeholder {
      color: #999;
      font-weight: bold;
      font-size: 1.6rem;
    }

    /* Code for Mozilla Firefox 4 to 18 */
    &:-moz-placeholder {
      opacity: 1;
      color: #999;
      font-weight: bold;
      font-size: 1.6rem;
    }

    /* Code for Mozilla Firefox 19+ */
    &::-moz-placeholder {
      opacity: 1;
      color: #999;
      font-weight: bold;
      font-size: 1.6rem;
    }
  }
  &__btn {
    width: 30%;

    padding: 2rem 4rem;
    font-size: 1.6rem;
    @include respond(phone) {
      width: 100%;
    }
  }
}
