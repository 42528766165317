*, :after, :before {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
  font-family: inherit;
}

html {
  scroll-behavior: smooth;
  font-size: 62.5%;
}

@media only screen and (max-width: 75em) {
  html {
    font-size: 59%;
  }
}

@media only screen and (max-width: 56.25em) {
  html {
    font-size: 56%;
  }
}

@media only screen and (min-width: 112.5em) {
  html {
    font-size: 65%;
  }
}

body {
  box-sizing: border-box;
  font-family: Source Sans Pro, sans-serif;
  line-height: 1.5;
  position: relative;
  overflow: hidden scroll;
}

a {
  color: inherit;
  text-decoration: none;
}

li {
  list-style: none;
}

button {
  cursor: pointer;
  border: none;
}

textarea {
  resize: none;
}

@font-face {
  font-family: Source Sans Pro;
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/sourcesanspro/v21/6xK1dSBYKcSV-LCoeQqfX1RYOo3qPZ7qsDJB9cme_xc.woff2") format("woff2");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Source Sans Pro;
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/sourcesanspro/v21/6xK1dSBYKcSV-LCoeQqfX1RYOo3qPZ7jsDJB9cme_xc.woff2") format("woff2");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Source Sans Pro;
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/sourcesanspro/v21/6xK1dSBYKcSV-LCoeQqfX1RYOo3qPZ7psDJB9cme_xc.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Source Sans Pro;
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/sourcesanspro/v21/6xK1dSBYKcSV-LCoeQqfX1RYOo3qPZ7nsDJB9cme.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/sourcesanspro/v21/6xK3dSBYKcSV-LCoeQqfX1RYOo3qNa7lujVj9_mf.woff2") format("woff2");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/sourcesanspro/v21/6xK3dSBYKcSV-LCoeQqfX1RYOo3qPK7lujVj9_mf.woff2") format("woff2");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/sourcesanspro/v21/6xK3dSBYKcSV-LCoeQqfX1RYOo3qNq7lujVj9_mf.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/sourcesanspro/v21/6xK3dSBYKcSV-LCoeQqfX1RYOo3qOK7lujVj9w.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/sourcesanspro/v21/6xKydSBYKcSV-LCoeQqfX1RYOo3ig4vwmhdu3cOWxy40.woff2") format("woff2");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/sourcesanspro/v21/6xKydSBYKcSV-LCoeQqfX1RYOo3ig4vwkxdu3cOWxy40.woff2") format("woff2");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/sourcesanspro/v21/6xKydSBYKcSV-LCoeQqfX1RYOo3ig4vwmRdu3cOWxy40.woff2") format("woff2");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/sourcesanspro/v21/6xKydSBYKcSV-LCoeQqfX1RYOo3ig4vwlxdu3cOWxw.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.heading-primary {
  text-transform: uppercase;
  letter-spacing: 3px;
  text-align: center;
  font-size: 6rem;
}

@media only screen and (max-width: 37.5em) {
  .heading-primary {
    font-size: 4.5rem;
  }
}

.heading-sec__mb-bg {
  margin-bottom: 11rem;
}

@media only screen and (max-width: 56.25em) {
  .heading-sec__mb-bg {
    margin-bottom: 8rem;
  }
}

.heading-sec__mb-med {
  margin-bottom: 9rem;
}

@media only screen and (max-width: 56.25em) {
  .heading-sec__mb-med {
    margin-bottom: 8rem;
  }
}

.heading-sec__main {
  text-transform: uppercase;
  letter-spacing: 3px;
  text-align: center;
  margin-bottom: 3.5rem;
  font-size: 4rem;
  display: block;
  position: relative;
}

.heading-sec__main--lt {
  color: #fff;
}

.heading-sec__main--lt:after {
  content: "";
  background: #fff !important;
}

.heading-sec__main:after {
  content: "";
  background: #0b832b;
  border-radius: 5px;
  width: 3rem;
  height: 5px;
  position: absolute;
  top: calc(100% + 1.5rem);
  left: 50%;
  transform: translateX(-50%);
}

@media only screen and (max-width: 37.5em) {
  .heading-sec__main:after {
    top: calc(100% + 1.2rem);
  }
}

.heading-sec__sub {
  text-align: center;
  color: #676767;
  max-width: 80rem;
  margin: auto;
  font-size: 2rem;
  font-weight: normal;
  line-height: 1.6;
  display: block;
}

@media only screen and (max-width: 37.5em) {
  .heading-sec__sub {
    font-size: 1.8rem;
  }
}

.heading-sec__sub--lt {
  color: #eee;
}

.heading-sm {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 2.2rem;
}

.main-container {
  width: 92%;
  max-width: 120rem;
  margin: auto;
}

.btn {
  color: #333;
  text-transform: uppercase;
  letter-spacing: 2px;
  background: #fff;
  border-radius: 5px;
  font-weight: bold;
  transition: transform .3s;
  display: inline-block;
  box-shadow: 0 5px 15px #00000026;
}

.btn:hover {
  transform: translateY(-3px);
}

.btn--bg {
  padding: 1.5rem 8rem;
  font-size: 2rem;
}

.btn--med {
  padding: 1.5rem 5rem;
  font-size: 1.6rem;
}

.btn--theme {
  color: #fff;
  background: #0b832b;
}

.btn--margined {
  margin-top: 2rem;
}

.btn--theme-inv {
  color: #0b832b;
  box-shadow: none;
  background: #fff;
  border: 2px solid #0b832b;
  padding: calc(1.5rem - 2px) calc(5rem - 2px);
}

.sec-pad {
  padding: 12rem 0;
}

@media only screen and (max-width: 56.25em) {
  .sec-pad {
    padding: 8rem 0;
  }
}

.text-primary {
  color: #fff;
  text-align: center;
  width: 100%;
  font-size: 2.2rem;
  line-height: 1.6;
}

@media only screen and (max-width: 37.5em) {
  .text-primary {
    font-size: 2rem;
  }
}

.d-none {
  display: none;
}

.paragraph-common {
  margin-bottom: 1rem;
}

.paragraph-common:last-child {
  margin-bottom: 4rem;
}

a.external {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  text-decoration-thickness: 2px;
}

a.external:hover {
  color: #000;
}

.home-screen {
  color: #fff;
  background-color: #0000;
  background-image: linear-gradient(to right, #0b832bcc, #0b832bcc), url("common-bg.70a61d43.svg");
  background-position: center;
  background-repeat: repeat, repeat;
  background-size: auto, auto;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  height: 100vh;
  min-height: 80rem;
  max-height: 120rem;
  position: relative;
}

@media only screen and (max-width: 37.5em) {
  .home-screen {
    height: unset;
    min-height: unset;
  }
}

.home-screen__mouse-scroll-cont {
  position: absolute;
  bottom: 3%;
  left: 50%;
  transform: translateX(-50%);
}

@media only screen and (max-width: 37.5em) {
  .home-screen__mouse-scroll-cont {
    display: none;
  }
}

.home-screen__content {
  width: 92%;
  max-width: 90rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 37.5em) {
  .home-screen__content {
    margin: auto;
    padding: 19rem 0 13rem;
    position: static;
    transform: translate(0);
  }
}

.home-screen__info {
  max-width: 80rem;
  margin: 3rem auto 0;
}

.home-screen__cta {
  text-align: center;
  margin-top: 5rem;
}

.about {
  background: #fafafa;
}

.about__main {
  grid-gap: 5rem;
  grid-template-columns: 1.5fr 1fr;
  margin-bottom: 3rem;
  display: grid;
}

@media only screen and (max-width: 56.25em) {
  .about__main {
    grid-gap: 2rem;
    text-align: left;
    grid-template-columns: 1fr;
    margin-bottom: 8rem;
  }
}

.about__main:last-child {
  margin-bottom: 0;
}

.about__main-img-cont {
  padding: 2rem 0;
  overflow: hidden;
}

.about__main-img {
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  display: block;
}

.about__sub {
  text-align: left;
  color: #676767;
  flex-direction: column;
  justify-content: center;
  font-size: 2.3rem;
  font-weight: normal;
  line-height: 1.6;
  display: flex;
}

@media only screen and (max-width: 37.5em) {
  .about__sub {
    font-size: 1.8rem;
  }
}

.about-details {
  grid-gap: 6rem 10rem;
  grid-template-columns: 1fr 1fr;
  display: grid;
}

@media only screen and (max-width: 56.25em) {
  .about-details {
    grid-gap: 4rem 8rem;
    grid-template-columns: 1fr;
  }
}

.about-title {
  margin-bottom: 3rem;
  font-size: 2.8rem;
  font-weight: bold;
}

@media only screen and (max-width: 37.5em) {
  .about-title {
    font-size: 2.4rem;
  }
}

.about-subtitle {
  margin-top: 2rem;
  margin-bottom: 1.4rem;
  font-size: 2.3rem;
  font-weight: bold;
}

@media only screen and (max-width: 37.5em) {
  .about-subtitle {
    font-size: 1.9rem;
  }
}

.about-topics-ul li {
  color: #676767;
  margin-left: 2.2rem;
  font-size: 1.8rem;
  line-height: 1.7;
  list-style: outside;
}

.projects__row {
  grid-gap: 5rem;
  grid-template-columns: 1fr 1.5fr;
  margin-bottom: 11rem;
  display: grid;
}

@media only screen and (max-width: 56.25em) {
  .projects__row {
    grid-gap: 2rem;
    text-align: left;
    grid-template-columns: 1fr;
    margin-bottom: 8rem;
  }
}

.projects__row:last-child {
  margin-bottom: 0;
}

.projects__row-img-cont {
  padding: 2rem 0;
  overflow: hidden;
}

.projects__row-img {
  object-fit: cover;
  width: 100%;
  padding: 1rem 2rem;
  display: block;
}

.projects__row-content {
  flex-direction: column;
  align-items: flex-start;
  padding: 2rem 0;
  display: flex;
}

@media only screen and (max-width: 56.25em) {
  .projects__row-content {
    align-items: baseline;
  }
}

.projects__row-content-title {
  margin-bottom: 2rem;
  font-size: 2.8rem;
  font-weight: bold;
}

@media only screen and (max-width: 37.5em) {
  .projects__row-content-title {
    font-size: 2.4rem;
  }
}

.projects__row-content-desc {
  color: #676767;
  max-width: 60rem;
  margin-bottom: 1rem;
  font-size: 1.8rem;
  line-height: 1.7;
}

@media only screen and (max-width: 37.5em) {
  .projects__row-content-desc {
    font-size: 1.7rem;
  }
}

.projects__row-content ul {
  margin-bottom: 1rem;
}

.projects__row-content ul li {
  color: #676767;
  margin-left: 2.2rem;
  font-size: 1.8rem;
  line-height: 1.7;
  list-style: outside;
}

.contact {
  background-color: #0000;
  background-image: linear-gradient(to right, #0b832bcc, #0b832bcc), url("common-bg.70a61d43.svg");
  background-position: center;
  background-repeat: repeat, repeat;
  background-size: cover;
  background-attachment: scroll, scroll;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
}

.contact__form-container {
  text-align: right;
  background: #fff;
  border-radius: 5px;
  width: 95%;
  max-width: 80rem;
  margin: 5rem auto 0;
  padding: 4rem;
  box-shadow: 0 0 10px #0000001a;
}

@media only screen and (max-width: 37.5em) {
  .contact__form-container {
    padding: 3rem;
  }
}

.contact__form-field {
  margin-bottom: 4rem;
}

@media only screen and (max-width: 37.5em) {
  .contact__form-field {
    margin-bottom: 3rem;
  }
}

.contact__form-label, .contact__form-message {
  color: #666;
  letter-spacing: 1px;
  text-align: left;
  margin-bottom: 1rem;
  font-size: 1.4rem;
  font-weight: bold;
  display: block;
}

.contact__form-label-success, .contact__form-message-success {
  color: #0b832b;
}

.contact__form-label-error, .contact__form-message-error {
  color: #830b0b;
}

.contact__form-input {
  color: #333;
  letter-spacing: 0;
  background: #f0f0f0;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  width: 100%;
  padding: 2rem;
  font-size: 1.6rem;
  font-weight: bold;
}

.contact__form-input::placeholder {
  color: #999;
  font-size: 1.6rem;
  font-weight: bold;
}

.contact__form-input::placeholder {
  color: #999;
  font-size: 1.6rem;
  font-weight: bold;
}

.contact__form-input:-ms-input-placeholder {
  color: #999;
  font-size: 1.6rem;
  font-weight: bold;
}

.contact__form-input::-moz-placeholder {
  color: #999;
  font-size: 1.6rem;
  font-weight: bold;
}

.contact__form-input:-moz-placeholder {
  opacity: 1;
  color: #999;
  font-size: 1.6rem;
  font-weight: bold;
}

.contact__form-input::-moz-placeholder {
  opacity: 1;
  color: #999;
  font-size: 1.6rem;
  font-weight: bold;
}

.contact__btn {
  width: 30%;
  padding: 2rem 4rem;
  font-size: 1.6rem;
}

@media only screen and (max-width: 37.5em) {
  .contact__btn {
    width: 100%;
  }
}

.header {
  z-index: 1000;
  background: #fff;
  width: 100%;
  position: fixed;
  box-shadow: 0 10px 100px #0000001a;
}

.header__content {
  justify-content: space-between;
  align-items: center;
  padding: 1rem 5rem;
  display: flex;
}

@media only screen and (max-width: 56.25em) {
  .header__content {
    padding: 0 2rem;
  }
}

.header__logo-container {
  cursor: pointer;
  color: #333;
  align-items: center;
  transition: color .3s;
  display: flex;
}

.header__logo-container:hover {
  color: #0b832b;
}

.header__logo-img-cont {
  perspective: 800px;
  background: #fff;
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  margin-right: 1.5rem;
  display: inline-block;
  overflow: hidden;
}

@media only screen and (max-width: 56.25em) {
  .header__logo-img-cont {
    width: 4.5rem;
    height: 4.5rem;
    margin-right: 1.2rem;
  }
}

.header__logo-img {
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  display: block;
}

.header .avatar {
  transform-style: preserve-3d;
  width: 100%;
  height: 100%;
  animation: 8s 7s infinite flipping;
  position: absolute;
}

.header .avatar .face {
  backface-visibility: hidden;
  width: 100%;
  height: 100%;
  position: absolute;
}

.header .avatar .back {
  box-sizing: border-box;
  transform: rotateY(180deg);
}

.header__logo-title {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 1.8rem;
  font-weight: bold;
}

.header__links {
  display: flex;
}

@media only screen and (max-width: 37.5em) {
  .header__links {
    display: none;
  }
}

.header__link {
  color: #333;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 2.2rem 2rem;
  font-size: 1.6rem;
  font-weight: bold;
  transition: color .3s;
  display: inline-block;
}

.header__link:hover {
  color: #0b832b;
}

@media only screen and (max-width: 56.25em) {
  .header__link {
    padding: 3rem 1.8rem;
    font-size: 1.5rem;
  }
}

.header__main-ham-menu-cont {
  width: 3rem;
  padding: 2.2rem 0;
  display: none;
}

@media only screen and (max-width: 37.5em) {
  .header__main-ham-menu-cont {
    display: block;
  }
}

.header__main-ham-menu, .header__main-ham-menu-close {
  width: 100%;
}

.header__sm-menu {
  visibility: hidden;
  opacity: 0;
  background: #fff;
  width: 100%;
  transition: all .3s;
  position: absolute;
  top: 100%;
  box-shadow: 0 5px 5px #0000001a;
}

.header__sm-menu--active {
  visibility: hidden;
  opacity: 0;
}

@media only screen and (max-width: 37.5em) {
  .header__sm-menu--active {
    visibility: visible;
    opacity: 1;
  }
}

.header__sm-menu-link a {
  color: #333;
  text-align: right;
  text-transform: uppercase;
  letter-spacing: 2px;
  border-bottom: 1px solid #eee;
  padding: 2.5rem 3rem;
  font-size: 1.6rem;
  font-weight: bold;
  text-decoration: none;
  transition: color .3s;
  display: block;
}

.header__sm-menu-link a:hover {
  color: #0b832b;
}

.header__sm-menu-link:first-child a {
  border-top: 1px solid #eee;
}

.header__sm-menu-link:last-child {
  border-bottom: 0;
}

@keyframes flipping {
  0% {
    transform: rotateY(0);
  }

  25% {
    transform: rotateY(180deg);
  }

  50% {
    transform: rotateY(180deg);
  }

  75% {
    transform: rotateY(0);
  }
}

.main-footer {
  color: #fff;
  background: #000;
}

.main-footer__upper {
  justify-content: space-between;
  padding: 8rem 0;
  display: flex;
}

@media only screen and (max-width: 56.25em) {
  .main-footer__upper {
    padding: 6rem 0;
  }
}

@media only screen and (max-width: 37.5em) {
  .main-footer__upper {
    display: block;
  }
}

.main-footer__row-1 {
  order: 2;
}

@media only screen and (max-width: 56.25em) {
  .main-footer__row-1 {
    margin-bottom: 5rem;
  }
}

.main-footer__row-2 {
  order: 1;
  width: 40%;
  max-width: 50rem;
}

@media only screen and (max-width: 56.25em) {
  .main-footer__row-2 {
    width: 100%;
  }
}

.main-footer__short-desc {
  color: #eee;
  margin-top: 2rem;
  font-size: 1.5rem;
  line-height: 1.7;
}

.main-footer__social-cont {
  text-align: right;
  margin-top: 2rem;
}

.main-footer__icon {
  fill: #fff;
  width: 2.6rem;
  height: 2.6rem;
  margin-right: 1rem;
}

.main-footer__icon--mr-none {
  margin-right: 0;
}

.main-footer__icon:hover {
  fill: #0b832b;
}

.main-footer__lower {
  color: #eee;
  text-align: center;
  border-top: 1px solid #444;
  padding: 4rem 0;
  font-size: 1.2rem;
}

.main-footer__lower a {
  margin-left: 2px;
  font-weight: bold;
  text-decoration: underline;
}

@media only screen and (max-width: 56.25em) {
  .main-footer__lower {
    padding: 3.5rem 0;
  }
}

.mouse {
  border: 2px solid #eee;
  border-radius: 60px;
  width: 25px;
  height: 40px;
  position: relative;
  overflow: hidden;
}

.mouse:before {
  content: "";
  opacity: 1;
  background-color: #eee;
  border-radius: 50%;
  width: 5px;
  height: 5px;
  animation: 1.3s infinite wheel;
  position: absolute;
  top: 7px;
  left: 50%;
  transform: translateX(-50%);
}

@keyframes wheel {
  to {
    opacity: 0;
    top: 27px;
  }
}

/*# sourceMappingURL=index.081ea041.css.map */
